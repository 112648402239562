<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="isLoading">
        <Loading />
      </div>
      <div v-else id="body-content-area">
        <div id="pagelayout">
           <div class="columns">
              <div class="column">
                <div class="block">
                  <div>
                    <div class="field">
                      <label class="label is-size-7">
                        Announcement Name
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <input class="input" type="text" name="name" v-model="input.name" />
                      </div>
                    </div>
                    <div class="field">
                      <label class="label is-size-7">
                        Announcement Message
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control">
                        <textarea class="input" name="name" style="min-height: 100px;" v-model="input.message"></textarea>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="field" style="margin-top: .5rem">
                      <label class="label is-size-7">
                        Recipients
                        <span class="has-text-warning" style="padding-left: 3px;">*</span>
                      </label>
                      <div class="control" style="display: flex; flex-direction: column;">
                        <div class="radio-container" @click="shouldShowRecipientsForm = false;">
                          <input class="radio" type="radio" name="recipients" :checked="!shouldShowRecipientsForm" value="false"/> All
                        </div>
                        <div class="radio-container" @click="shouldShowRecipientsForm = true;">
                          <input class="radio" type="radio" name="recipients" :checked="shouldShowRecipientsForm" value="true"/> Custom
                        </div>
                      </div>
                    </div>
                    <div class="level" v-if="shouldShowRecipientsForm">
                      <div class="level-left" style="width: 45%;">
                        <div class="field" style="width: 100%;">
                          <label class="label is-size-7">Institution List</label>
                          <div class="control">
                            <div class="select is-multiple" style="width: 100%;">
                              <select name="institutionSelect" ref="carrierSelectFrom" v-model="selectedAvailableInstitutions" multiple :size="availableInstitutions.length" style="width: 100%; height: 250px!important;">
                                <option v-for="institution in sortArrayOfObjects(availableInstitutions, 'id')" :value="institution.id" :key="institution.id">{{institution.id}} {{institution.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="level-center" style="width: 10%;">
                        <div id="buttonblock" class="has-text-centered">
                          <div class="block">
                            <a v-on:click="moveInstitutionsRight" class="button is-accent is-outlined">
                              <i class="fas fa-caret-right fa-2x"></i>
                            </a>
                          </div>
                          <div class="block">
                            <a v-on:click="moveInstitutionsLeft" class="button is-accent is-outlined">
                              <i class="fas fa-caret-left fa-2x"></i>
                            </a>
                          </div>
                        </div>
                      </div>

                      <div class="level-right" style="width: 45%;">
                        <div class="field" style="width: 100%;">
                          <label class="label is-size-7">Selected Institutions</label>
                          <div class="control">
                            <div class="select is-multiple" style="width: 100%;">
                              <select ref="carrierSelectTo" multiple :size="getInstitutionsCount(input.institutionIds)" v-model="selectedUsedInstitutions" style="width: 100%; height: 250px!important;">
                                <option v-if="selectedInstitutions.length === 0" disabled>Select an Institution</option>
                                <option
                                  v-for="institution in sortArrayOfObjects(selectedInstitutions, 'id')"
                                  :key="'sel' + institution.id"
                                  :value="institution.id"
                                >{{institution.id}} {{institution.name}}</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="buttons" style="margin-top: 2rem;">
                    <button :disabled="!$hasPermissions(clientSession, ['ANNOUNCEMENTS'], 2) || errorsList.length > 0"   v-bind:class="{ 'is-loading': isSaving, 'button is-accent': true }" @click.prevent="() => { if (!hasSaved) { save() } else { $router.back() }}">{{ hasSaved ? 'Back' : 'Save'}}</button>
                    <button :disabled="hasSaved" class="button is-light" @click.prevent="$router.push({ name: 'Announcements'})">Cancel</button>
                  </div>
                </div>
              </div>

              <div class="column">
                <!-- INSTITUTION INFORMATION -->
                <div id="institution-info-section">
                  <div id="institution-info-section-data" v-if="institutions" class="block">
                    <div class="columns is-vcentered">
                      <div class="column">

                        <div class="field">
                          <label class="label is-size-7">
                            Start Date
                            <span class="has-text-warning" style="padding-left: 3px;">*</span>
                          </label>
                          <kendo-datetimepicker id="uploaddate"
                            name="uploaddate"
                            required="required"
                            v-model="input.startDate"
                          />
                        </div>

                        <div class="field">
                          <label class="label is-size-7">
                            Expire Date
                            <span class="has-text-warning" style="padding-left: 3px;">*</span>
                          </label>
                          <kendo-datetimepicker id="uploaddate"
                            name="uploaddate"
                            required="required"
                            v-model="input.expirationDate"
                            :class="errorsList.includes('Expiration date must be after the start date.') ? 'has-text-danger' : ''"
                          />
                          <label for="uploaddate">
                            <span class="has-text-danger" v-if="errorsList.includes('Expiration date must be after the start date.')">Expiration date must be after the start date.</span>
                          </label>
                        </div>
                        <div>
                            <v-popover
                              style="height: 100%; width: 75%; text-overflow: clip!important;"
                              :open="isExpireNowPromptOpen"
                              @hide="isExpireNowPomptOpen = false"
                              @auto-hide="isExpireNowPomptOpen = false"
                              @close-directive="isExpireNowPomptOpen = false"
                              :autoHide="false"
                              :placement="'bottom-center'"
                              :trigger="'manual'"
                            >
                            <button :class="['button expire-now',  !expirationIsInFuture() ? 'disabled' : '']" :disabled="isExpireNowPromptOpen && expirationIsInFuture() || !$hasPermissions(clientSession, ['ANNOUNCEMENTS'], 2)" @click.prevent=" () => { if (expirationIsInFuture()) { isExpireNowPromptOpen = true }}"><i style="margin-right: .75rem;" class="fa fa-stopwatch"></i> Expire Now</button>
                            <!-- This will be the content of the popover -->
                            <template @blur="isExpireNowPomptOpen = false" slot="popover" id="popover">
                              <div class="popover-contain">
                                Are you sure you want to save your changes and expire this announcement now?
                                <div class="actions">
                                  <button :disabled="errorsList.length > 0" style="margin-left: .5rem" :class="['button is-small is-accent', isSaving ? 'isSaving' : '', errorsList.length > 0 ? 'disabled' : '']" @click.prevent="expire(); isExpireNowPromptOpen = false">Confirm</button>
                                  <button class="button is-small is-warning" cancel @click.prevent="isExpireNowPromptOpen = false">Cancel</button>
                                </div>
                              </div>
                            </template>
                            </v-popover>
                        </div>
                      </div>
                      <div class="column">
                        <div class="has-text-centered">
                          <label class="label is-size-7">
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import Loading from './Loading'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'
import { DateinputsInstaller } from '@progress/kendo-dateinputs-vue-wrapper'

import moment from 'moment-timezone'

Vue.use(DateinputsInstaller)
let pageName = 'Announcement Details'

export default {
  components: {
    Loading
  },
  props: {
    model: {
      default: () => {
        return {
          id: 0,
          message: '',
          name: '',
          startDate: '',
          expirationDate: '',
          isActive: false,
          institutionIds: [],
          hasSaved: false
        }
      }
    }
  },
  data () {
    return {
      isLoading: true,
      announcement: [],
      institutions: [],
      availableInstitutions: [],
      selectedAvailableInstitutions: [],
      selectedInstitutions: [],
      selectedUsedInstitutions: [],
      isExpireNowPromptOpen: false,
      shouldShowRecipientsForm: false,
      isSaving: false,
      errorsList: [],
      input: {
        id: 0,
        message: '',
        name: '',
        startDate: this.formatStartDate('MM/DD/YYYY hh:mm a'),
        expirationDate: this.formatEndDate('MM/DD/YYYY hh:mm a'),
        isActive: false,
        institutionIds: []
      }
    }
  },
  watch: {
    institutions (institutions) {
      if (this.input.institutionIds !== undefined) {
        this.availableInstitutions = institutions
        // Find all of the institutions that are are already
        // on the input and remove them from the available
        // Institutions.
        this.input.institutionIds.map(_institution => {
          this.availableInstitutions.filter(_availableInstitution => {
            if (_availableInstitution.id !== _institution.id) {
              return _availableInstitution
            }
          })
        })
      }
    },
    'input.expirationDate': function () {
      if (moment(this.input.expirationDate).isBefore(moment(this.input.startDate))) {
        this.errorsList.push(
          'Expiration date must be after the start date.'
        )
      } else {
        this.errorsList = this.errorsList.filter(
          _error => _error !== 'Expiration date must be after the start date.'
        )
      }
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    pageheading () {
      return pageName
    }
  },
  created () {
    try {
      let model = JSON.parse(this.model)

      if (model.id !== 0) {
        this.announcement = this.clone(model, true)
      }
    } catch (error) {
      //
    }
  },
  mounted () {
    let that = this

    this.api().getInstitutions((_response, _error) => {
      if (_error) {
        that.handleApiErr(_error)
      }

      if (_response) {
        that.institutions = _response.records
        that.getAnnouncement(false)
      }
    })
  },
  methods: {
    expirationIsInFuture () {
      return moment(this.announcement.expirationDate).isAfter(moment())
    },
    getInstitutionsCount (institutions) {
      return institutions.length
    },
    formatEndDate (format) {
      let dateTime = moment().add(12, 'hours')
      return dateTime.format(format)
    },

    formatStartDate (format) {
      let dateTime = moment()
      return dateTime.format(format)
    },
    getAnnouncement (isRefresh) {
      let that = this
      if ((this.$route.params.id && !this.model.id) || (parseInt(this.model.id) !== 0 && isRefresh)) {
        this.api().getAnnouncement(this.$route.params.id, (_result, _error) => {
          if (_error) {
            that.errorsList.push(_error)
          }

          if (_result) {
            that.announcement = _result
            that.processAnnouncement()
          }
        })
      }

      if (parseInt(this.model.id) === 0) {
        this.announcement = {
          message: '',
          name: '',
          startDate: this.formatStartDate('MM/DD/YYYY hh:mm a'),
          expirationDate: this.formatEndDate('MM/DD/YYYY hh:mm a'),
          institutionIds: []
        }
      }

      that.processAnnouncement()
    },
    processAnnouncement () {
      let that = this
      try {
        this.announcement.institutionIds = this.announcement.institutionIds.split(', ')
      } catch (error) {

      }

      this.input = this.announcement

      let institutions = this.institutions.filter(_institution => {
        if (that.input.institutionIds.includes(_institution.id.toString())) {
          return _institution
        }
      })

      this.availableInstitutions = this.availableInstitutions.filter(_institution => {
        if (!that.input.institutionIds.includes(_institution.id.toString())) {
          return _institution
        }
      })

      this.input.startDate = moment(this.input.startDate).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM/DD/YYYY hh:mm A')
      this.input.expirationDate = moment(this.input.expirationDate).tz(Intl.DateTimeFormat().resolvedOptions().timeZone).format('MM/DD/YYYY hh:mm A')

      this.selectedInstitutions = institutions

      if (this.selectedInstitutions.length > 0) {
        this.shouldShowRecipientsForm = true
      }
      this.isLoading = false
    },
    save () {
      if (this.input.id !== 0 && this.input.id !== undefined) {
        this.update()
      } else {
        this.create()
      }
    },
    async create () {
      try {
        this.isSaving = true
        let institutions = []

        this.selectedInstitutions.map(_institution => {
          institutions.push(_institution.id)
        })

        let payload = {
          siteId: this.activeSite.id,
          name: this.input.name,
          message: this.input.message,
          startDate: this.input.startDate,
          expirationDate: this.input.expirationDate,
          isActive: true,
          institutionIds: this.shouldShowRecipientsForm ? institutions.join(', ') : ''
        }

        let response = await this.axios.post('/announcements', payload)

        if (response.status >= 200 && response.status < 300) {
          this.$router.push('/announcement-management')
        }
      } catch (error) {
      } finally {
        this.isSaving = false
      }
    },
    async update () {
      try {
        this.isSaving = true
        let institutions = []

        this.selectedInstitutions.map(_institution => {
          institutions.push(_institution.id)
        })

        let payload = {
          id: this.input.id,
          siteId: this.activeSite.id,
          name: this.input.name,
          message: this.input.message,
          startDate: moment(this.input.startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
          expirationDate: moment(this.input.expirationDate).utc().format('YYYY-MM-DD HH:mm:ss'),
          isActive: true,
          institutionIds: this.shouldShowRecipientsForm ? institutions.join(', ') : ''
        }

        let response = await this.axios.put(`/announcements/${this.input.id}`, payload)

        if (response.status >= 200 && response.status < 300) {
          this.$router.push('/announcement-management')
        }
      } catch (error) {
      } finally {
        this.isSaving = false
      }
    },
    async expire () {
      try {
        this.isSaving = true
        let institutions = []

        this.selectedInstitutions.map(_institution => {
          institutions.push(_institution.id)
        })

        let payload = {
          id: this.input.id,
          siteId: this.activeSite.id,
          name: this.input.name,
          message: this.input.message,
          startDate: moment(this.input.startDate).utc().format('YYYY-MM-DD HH:mm:ss'),
          expirationDate: moment().utc().format('YYYY-MM-DD HH:mm:ss'),
          isActive: true,
          institutionIds: this.shouldShowRecipientsForm ? institutions.join(', ') : ''
        }

        let response = await this.axios.put(`/announcements/${this.input.id}`, payload)

        if (response.status >= 200 && response.status < 300) {
          this.getAnnouncement(true)
        }
      } catch (error) {
      } finally {
        this.isSaving = false
        this.isExpireNowPomptOpen = false
        this.hasSaved = true
      }
    },
    moveInstitutionsRight () {
      let institutions = []

      this.selectedAvailableInstitutions.map(_institutionId => {
        institutions = [...institutions, ...this.institutions.filter(_institution => {
          if (_institution.id === _institutionId) {
            return _institution
          }
        })]
      })

      this.selectedInstitutions = [...this.selectedInstitutions, ...institutions]

      // Find all of the institutions that are are already
      // on the input and remove them from the available
      // Institutions.
      institutions.map(_institution => {
        this.availableInstitutions = this.availableInstitutions.filter(_availableInstitution => {
          if (_availableInstitution.id !== _institution.id) {
            return _availableInstitution
          }
        })
      })

      this.selectedAvailableInstitutions = []
    },
    moveInstitutionsLeft () {
      let institutions = []

      this.selectedUsedInstitutions.map(_institutionId => {
        institutions = [...institutions, ...this.institutions.filter(_institution => {
          if (_institution.id === _institutionId) {
            return _institution
          }
        })]
      })

      this.availableInstitutions = [...this.availableInstitutions, ...institutions]

      // Find all of the institutions that are are already
      // on the input and remove them from the available
      // Institutions.
      institutions.map(_institution => {
        this.selectedInstitutions = this.selectedInstitutions.filter(_availableInstitution => {
          if (_availableInstitution.id !== _institution.id) {
            return _availableInstitution
          }
        })
      })

      this.selectedUsedInstitutions = []
    }
  }
}
</script>

<style scoped>
  .expire-now {
    background-color: #8c8c88;
    color: white;
  }

  .expire-now:hover {
    background-color: #e69138;
  }

  .expire-now:disabled {
    background-color:#e69138!important;
  }

  .expire-now.disabled {
    background-color: #8c8c88;
  }
</style>
<style>
.tooltip .tooltip-inner {
  background: white;
  width: 205px;
  color: #363636;
  border: 1px solid #ececec;
  border-radius: .25rem;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
        margin-right: 5px;
        margin-left: 5px;
    border-color: #ececec;
        border-top-color: #ececec;
        border-bottom-color: #ececec;
        border-left-color: #ececec;
}

.popover-contain {
  text-align: left;
}

.popover-contain .actions {
  margin: 0 auto;
  margin-top: .5rem;
  height: 32.5px;
}

.popover-contain .actions button {
  margin-left: .5rem;
  float: right;
}
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}
.grid-button {
  margin-bottom: 5px;
}
.grid-header-controls {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.k-datetimepicker {
  width: 225px;
}

.radio-container {
  cursor: pointer;
}

::-webkit-scrollbar {
    width: 0;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
    background: #FF0000;
}
</style>
